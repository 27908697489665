import React from "react";
import {Box, Typography} from "@mui/material";
import IconBg from "../atoms/IconBg";

export interface DetailsWithIconProps {
  icon: React.ComponentType;
  title: React.ReactNode;
}

export default function DetailsWithIcon(props: React.PropsWithChildren<DetailsWithIconProps>) {
  return (
    <Box sx={{ p: { xs: 2, md: 0 }}}>
      <IconBg>
        <props.icon />
      </IconBg>
      <Typography variant="h2" color="primary.light" sx={{ mt: "26px", mb: "18px" }}>
        {props.title}
      </Typography>
      <Typography>
        {props.children}
      </Typography>
    </Box>
  )
}