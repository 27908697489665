import React from "react";
import {Box, BoxProps} from "@mui/material";

export default function StepperContent(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        maxWidth: { xs: "100%", md: "16.6666%" },
        display: "flex",
        flexWrap: "wrap",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "flex-start"
      }}
    />
  )
}