import React from "react";
import {Box, useTheme} from "@mui/material";

export default function IconBg(props: React.PropsWithChildren<any>) {
  const theme = useTheme();

  return <Box
    sx={{
      background: theme.palette.primary.light,
      borderRadius: "40px",
      borderColor: "#C3E2FF",
      borderWidth: "10px",
      borderStyle: "solid",
      width: "106px",
      height: "106px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.contrastText,
    }}>
      {props.children}
    </Box>
}