import React from "react";
import { Box, BoxProps } from "@mui/material";

export default function StepperIconBg(props: BoxProps) {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        aspectRatio: "1",
        width: "100%",
        padding: { xs: "3rem", md: "2rem", lg: "3rem" },
        borderRadius: "50%",
        maxWidth: "226px",
        maxHeight: "226px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(241 241 248 / 57%)"
      }}
      {...props}
    />
  )
}