import * as React from "react";
import loadable from "@loadable/component";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { graphql } from "gatsby";
import { useTranslation, Helmet } from "gatsby-plugin-react-i18next";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import { StaticImage } from "gatsby-plugin-image";
import DetailsWithIcon from "components/molecules/DetailsWithIcon";
import { MEET_STAFF, STORE_REVIEWS, WHY_CHOSE } from "../constants";
import MobileDownloadImg from "assets/images/mobile-download.svg";
import MobileDocumentSign from "assets/images/document-sign.svg";
import MobilePhysicianImg from "assets/images/mobile-physician.svg";
import ArrowRight from "assets/shapes/arrow-right.svg";
import appPictureTop from "assets/images/app-top.png";
import appPictureBottom from "assets/images/app-bottom.png";
import StepperIconBg from "components/atoms/StepperIconBg";
import StepperContent from "components/atoms/StepperContent";
import { ROUTES_PL } from "config";
const StaffCard = loadable(() => import("components/molecules/StaffCard"));
const Carousel = loadable(() => import("components/molecules/Carousel"));
const ReviewCard = loadable(() => import("components/molecules/ReviewCard"));
const Picture = loadable(() => import("components/atoms/Picture"));
const DownloadBadge = loadable(
  () => import("components/molecules/DownloadBadge")
);
const FadeIn = loadable(() => import("components/animations/FadeIn"));
const Pulse = loadable(() => import("components/animations/Pulse"));

const IndexPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok</title>
        <meta name="description" content={t("meta-description")} />
      </Helmet>
      <Section>
        <Grid
          container
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: { sx: 2, md: 4, lg: 8, xl: 10 },
                display: "flex",
                flexDirection: "column",
                alignContent: "space-between",
                justifyContent: "center",
                alignItems: { xs: "stretch", md: "flex-start" },
              }}
            >
              <Typography
                variant="h1"
                color="primary"
                sx={{
                  mb: 2,
                  p: { xs: 2, md: 0 },
                  textAlign: { xs: "center", md: "left" },
                }}
                dangerouslySetInnerHTML={{
                  __html: t("slogan"),
                }}
              />
              <Typography
                variant="body1"
                component="ul"
                sx={{ mb: 2 }}
                dangerouslySetInnerHTML={{
                  __html: t("pros"),
                }}
              />
              <Button
                variant="contained"
                size="large"
                color="secondary"
                sx={{
                  flexGrow: { xs: 1, md: "unset" },
                  m: { xs: 2, md: 0 },
                  mb: { xs: 2, md: 0 },
                  display: { xs: "none", md: "flex" },
                }}
                href="/register"
              >
                {t("registerBtn")}
              </Button>
              <DownloadBadge
                sx={{
                  flexGrow: { xs: 1, md: "unset" },
                  m: { xs: 2, md: 0 },
                  mb: { xs: 2, md: 0 },
                  display: { xs: "flex", md: "none" },
                }}
                button
              >
                {t("downloadBtn")}
              </DownloadBadge>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", md: "70%" },
                  mt: 4,
                  mb: 4,
                  flexWrap: { xs: "wrap", md: "nowrap" },
                  justifyContent: { xs: "center", md: "unset" },
                }}
              >
                <Typography
                  variant="h5"
                  color="primary.dark"
                  sx={{
                    width: { xs: "100%", md: "unset" },
                    textAlign: { xs: "center", md: "unset" },
                  }}
                >
                  {t("worksWith1")}
                </Typography>
                <Box
                  sx={{
                    color: theme.palette.primary.dark,
                    width: "122px",
                    maxHeight: "60px",
                    margin: 2,
                  }}
                >
                  <StaticImage src="../assets/images/nfz.png" alt="NFZ" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Carousel id="frontPicture" navigation={false} fullWidth>
              <StaticImage
                src="../assets/images/DokDok_App_Commercial_2_1000x1000.jpg"
                alt="front picture 1"
                loading="lazy"
                style={{ width: "100%" }}
              />
              <StaticImage
                src="../assets/images/DokDok_App_Commercial_9_1000x1000.jpg"
                alt="front picture 2"
                loading="lazy"
                style={{ width: "100%" }}
              />
              <StaticImage
                src="../assets/images/DokDok_App_Commercial_19_1000x1000.jpg"
                alt="front picture 3"
                loading="lazy"
                style={{ width: "100%" }}
              />
            </Carousel>
          </Grid>
        </Grid>
      </Section>
      <Section id="czym-jest-dok-dok">
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              textAlign="center"
              color="primary"
              sx={{
                display: { xs: "block", md: "none" },
                pb: { xs: 4, md: 0 },
              }}
            >
              {t("whatIs-slogan")}
            </Typography>
            <Box
              sx={{
                position: "relative",
                p: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 },
              }}
            >
              <Picture />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={{ xs: 4, md: 8 }} pr={2}>
              <Typography
                variant="h1"
                color="primary"
                sx={{
                  display: { xs: "none", md: "block" },
                  mb: { xs: 2, sm: 4, md: 6 },
                }}
                dangerouslySetInnerHTML={{
                  __html: t("whatIs-slogan"),
                }}
              />
              <Typography
                variant="body1"
                sx={{ p: { xs: 2, md: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: t("whatIs-description"),
                }}
              />
            </Box>
            <Box mb={{ xs: 4, md: 8 }} pr={2}>
              <Typography
                variant="h1"
                color="primary"
                sx={{
                  p: { xs: 2, md: 0 },
                  mb: { xs: 2, sm: 4, md: 6 },
                  textAlign: { xs: "center", md: "left" },
                }}
                dangerouslySetInnerHTML={{
                  __html: t("noCost-slogan"),
                }}
              />
              <Typography
                variant="body1"
                sx={{ p: { xs: 2, md: 0 }, mb: { xs: 6, md: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: t("noCost-description"),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section id="jak-dziala-dok-dok" background="light">
        <Typography
          variant="h1"
          color="primary.contrastText"
          align="center"
          padding={{ xs: 2, md: 4, lg: 8, xl: 10 }}
          pt={{ xs: 4, md: 0 }}
        >
          {t("howItWorks-slogan")}
          <br />
          {t("howItWorks-slogan2")}
        </Typography>
        <FadeIn>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              color: "primary.contrastText",
            }}
          >
            <Carousel id="how-it-works" loop={false} initialSlide={0}>
              <StepperContent>
                <StepperIconBg>
                  <MobileDownloadImg />
                </StepperIconBg>
                <Box sx={{ mt: 1, p: 2, flexGrow: 1, width: "100%" }}>
                  <Typography variant="h3" sx={{ mb: "1rem" }}>
                    1. {t("howItWorks-download-title")}
                  </Typography>
                  <Typography>
                    {t("howItWorks-download-description")}
                  </Typography>
                </Box>
              </StepperContent>
              <StepperContent>
                <StepperIconBg>
                  <MobileDocumentSign style={{ marginRight: "1rem" }} />
                </StepperIconBg>
                <Box sx={{ mt: 1, p: 2, flexGrow: 1, width: "100%" }}>
                  <Typography variant="h3" sx={{ mb: "1rem" }}>
                    2. {t("howItWorks-signDeclaration-title")}
                  </Typography>
                  <Typography>
                    {t("howItWorks-signDeclaration-description")}
                  </Typography>
                </Box>
              </StepperContent>
              <StepperContent>
                <StepperIconBg>
                  <MobilePhysicianImg style={{ marginLeft: "1rem" }} />
                </StepperIconBg>
                <Box sx={{ mt: 1, p: 2, flexGrow: 1, width: "100%" }}>
                  <Typography variant="h3" sx={{ mb: "1rem" }}>
                    3. {t("howItWorks-start-title")}
                  </Typography>
                  <Typography>{t("howItWorks-start-description")}</Typography>
                </Box>
              </StepperContent>
            </Carousel>
          </Box>
        </FadeIn>
        <Box
          sx={{
            padding: { xs: 2, md: 4, lg: 8, xl: 10 },
            display: { xs: "none", md: "flex" },
            color: "primary.contrastText",
          }}
        >
          <StepperContent>
            <FadeIn delay={250}>
              <StepperIconBg>
                <MobileDownloadImg />
              </StepperIconBg>
            </FadeIn>
            <Box sx={{ mt: "2rem " }}>
              <FadeIn delay={250}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  {t("howItWorks-download-title")}
                </Typography>
                <Typography>{t("howItWorks-download-description")}</Typography>
              </FadeIn>
            </Box>
          </StepperContent>
          <Box
            sx={{
              flexGrow: 0.3,
              color: "",
              margin: "0 auto",
              paddingTop: "7%",
            }}
          >
            <FadeIn delay={400}>
              <ArrowRight />
            </FadeIn>
          </Box>
          <StepperContent>
            <FadeIn delay={550}>
              <StepperIconBg>
                <MobileDocumentSign style={{ marginRight: "1rem" }} />
              </StepperIconBg>
            </FadeIn>
            <Box sx={{ mt: "2rem " }}>
              <FadeIn delay={550}>
                <Typography variant="h3" sx={{ mb: "1rem" }}>
                  {t("howItWorks-signDeclaration-title")}
                </Typography>
                <Typography>
                  {t("howItWorks-signDeclaration-description")}
                </Typography>
              </FadeIn>
            </Box>
          </StepperContent>
          <Box
            sx={{
              flexGrow: 0.3,
              color: "",
              margin: "0 auto",
              paddingTop: "7%",
            }}
          >
            <FadeIn delay={700}>
              <ArrowRight />
            </FadeIn>
          </Box>
          <StepperContent>
            <FadeIn delay={850}>
              <StepperIconBg>
                <MobilePhysicianImg style={{ marginLeft: "1rem" }} />
              </StepperIconBg>
            </FadeIn>
            <Box sx={{ mt: "2rem " }}>
              <FadeIn delay={850}>
                <Typography variant="h3" sx={{ mb: "10%" }}>
                  {t("howItWorks-start-title")}
                </Typography>
                <Typography>{t("howItWorks-start-description")}</Typography>
              </FadeIn>
            </Box>
          </StepperContent>
        </Box>
      </Section>
      <Section id="dlaczego-warto">
        <Typography
          variant="h1"
          color="primary.light"
          textAlign="center"
          sx={{
            mt: { xs: 4, md: 4, lg: 8, xl: 10 },
            mr: { xs: 2, md: 4, lg: 8, xl: 10 },
            ml: { xs: 2, md: 4, lg: 8, xl: 10 },
          }}
        >
          {t("whyToChose-slogan")}
        </Typography>
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Grid container spacing={8} sx={{ p: 12 }}>
            {WHY_CHOSE.map((item, index) => (
              <Grid key={item.title} item md={4}>
                <Pulse delay={(index + 1) * 200}>
                  <DetailsWithIcon icon={item.icon} title={t(item.title)}>
                    {t(item.content)}
                  </DetailsWithIcon>
                </Pulse>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <FadeIn>
            <Carousel id="why-choose" color="primary.light">
              {WHY_CHOSE.map((item) => (
                <Grid
                  key={item.title}
                  item
                  xs={12}
                  sx={{ textAlign: "center", minHeight: "15rem" }}
                >
                  <DetailsWithIcon icon={item.icon} title={t(item.title)}>
                    {t(item.content)}
                  </DetailsWithIcon>
                </Grid>
              ))}
            </Carousel>
          </FadeIn>
        </Box>
      </Section>
      <Section background="dark" fullWidth>
        <Box
          sx={{
            pr: { xs: 2, md: 4, lg: 8, xl: 10 },
            pl: { xs: 2, md: 4, lg: 8, xl: 10 },
            pt: { xs: 4, md: 4, lg: 8, xl: 10 },
            pb: { xs: 0, md: 4, lg: 8, xl: 10 },
          }}
        >
          <Typography
            textAlign="center"
            variant="h1"
            color="primary.contrastText"
          >
            {t("meetOurStaff-slogan")}
          </Typography>
          <Typography
            textAlign="center"
            variant="h3"
            color="primary.contrastText"
          >
            {t("meetOurStaff-slogan2")}
          </Typography>
        </Box>
        <FadeIn delay={500}>
          <Carousel id="meet-staff">
            {MEET_STAFF.map((item) => (
              <StaffCard
                key={item.avatarSrc}
                title={item.title}
                // avatarURL={item.avatarSrc}
                name={item.name}
              >
                {item.description}
              </StaffCard>
            ))}
          </Carousel>
        </FadeIn>
        <Box sx={{ p: { xs: 0, sm: 4, md: 6, lg: 8, xl: 10 } }} />
      </Section>
      <Section>
        <Grid
          container
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
            maxHeight: { xs: "initial", md: "960px" },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "center", md: "initial" },
              textAlign: { xs: "center", md: "initial" },
            }}
          >
            <Typography
              variant="h1"
              color="primary"
              sx={{
                mb: { xs: 4, md: 0, lg: 0 },
                mr: { xs: 2, md: 4, lg: 8 },
                ml: { xs: 2, md: 4, lg: 8 },
                mt: { xs: 4, md: 4, lg: 8 },
              }}
            >
              Zamień swoją przychodnię na DokDok
            </Typography>
            <Typography sx={{ m: { xs: "1rem", md: 8 } }}>
              Mamy kontrakt z Narodowym Funduszem Zdrowia, dlatego korzystanie
              zarówno z naszej aplikacji, jak i naszych nowoczesnych przychodni
              jest bezkosztowe. Ze skierowaniem od naszego lekarza, podstawowe
              badania laboratoryjne są również darmowe.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                mt: { xs: 4, md: 0, lg: 0 },
                mr: { xs: 2, md: 4, lg: 8 },
                ml: { xs: 2, md: 4, lg: 8 },
                mb: { xs: 4, md: 4, lg: 8 },
              }}
              href={ROUTES_PL.directions}
            >
              Odwiedź nas w placówce
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxHeight: { md: "50vw" } }}>
              <StaticImage
                src="../assets/images/przychodnia@2x.jpg"
                alt="przychodnia"
              />
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section id="opinie" background="light" fullWidth>
        <Box
          sx={{
            pt: { xs: 4, md: 4, lg: 8, xl: 10 },
            pr: { xs: 2, md: 4, lg: 8, xl: 10 },
            pl: { xs: 2, md: 4, lg: 8, xl: 10 },
            pb: { xs: 0, md: 4, lg: 8, xl: 10 },
          }}
        >
          <Typography
            textAlign="center"
            variant="h1"
            color="primary.contrastText"
          >
            {t("reviews-slogan")}
          </Typography>
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="normal"
            color="primary.contrastText"
          >
            {t("reviews-slogan2")}
          </Typography>
        </Box>
        <FadeIn delay={500}>
          <Carousel id="store-reviews">
            {STORE_REVIEWS.map((item) => (
              <ReviewCard
                key={item.author}
                author={item.author}
                score={item.rating}
                date={item.date}
              >
                {item.description}
              </ReviewCard>
            ))}
          </Carousel>
        </FadeIn>
        <Box sx={{ p: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 } }} />
      </Section>
      <Section background="light">
        <Box
          sx={{
            display: "flex",
            flexFlow: { xs: "column-reverse", md: "row" },
            pt: { md: "126px" },
            pl: { xs: "20px", md: "126px" },
            pr: { xs: "20px", md: 0 },
            height: { md: "260px" },
            margin: { xs: 0, md: "0 20px" },
            backgroundColor: { md: theme.palette.primary.dark },
            borderRadius: "10px",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              maxWidth: { sx: "initial", md: "60%" },
            }}
          >
            <Typography
              variant="h1"
              color="primary.contrastText"
              sx={{ mb: 6 }}
            >
              {t("downloadApp-slogan")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "block" },
              width: { md: "380px" },
            }}
          >
            <Box
              alt="app top"
              component="img"
              loading="lazy"
              src={appPictureTop}
              sx={{
                width: { xs: "100%", md: "auto" },
                height: { xs: "auto", md: "calc(100% + 60px)" },
                maxWidth: "392px",
                margin: "0 auto",
                position: { md: "absolute" },
                top: "-60px",
                aspectRatio: "1",
                right: "60px",
              }}
            />
          </Box>
        </Box>
      </Section>
      <Section>
        <Box
          sx={{
            display: "flex",
            textAlign: { sx: "center", md: "left" },
            flexFlow: { xs: "column-reverse", md: "row" },
            pb: { md: "126px" },
            pl: { xs: "20px", md: "126px" },
            pr: { xs: "20px", md: 0 },
            height: { md: "260px" },
            margin: { xs: 0, md: "0 20px" },
            mb: { md: "120px" },
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "10px",
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            bottom: 0,
            position: "relative",
          }}
        >
          <Box
            sx={{
              pb: { xs: "60px", md: "unset" },
              maxWidth: { sx: "initial", md: "60%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              variant="h1"
              color="primary.contrastText"
              sx={{ mt: 2, mb: 4, display: { sx: "block", md: "none" } }}
            >
              {t("downloadApp-slogan")}
            </Typography>
            <Typography
              variant="h3"
              color="primary.contrastText"
              sx={{ mb: 2 }}
            >
              {t("downloadApp-slogan2")}
            </Typography>
            <DownloadBadge
              button={!isMobile}
              displayAll
              sx={{ display: "flex" }}
            >
              {t("registerBtn")}
            </DownloadBadge>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "block" },
              width: { md: "380px" },
            }}
          >
            <Box
              alt="app bottom"
              component="img"
              loading="lazy"
              src={appPictureBottom}
              sx={{
                width: { xs: "100%", md: "auto" },
                height: { xs: "auto", md: "calc(100% + 60px)" },
                maxWidth: "392px",
                margin: "0 auto",
                position: { md: "absolute" },
                top: "0",
                aspectRatio: "1",
                right: "60px",
              }}
            />
          </Box>
        </Box>
      </Section>
    </SiteLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
